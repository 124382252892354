<template><Trait preserveAspectRatio="none"/></template>

<script>
export default {
  name: 'TraitSeparation',
  components: {
    Trait: () => import('./inline.trait_separation.svg'),
  },
}
</script>
